import React, {useState, useEffect} from 'react';
import { db } from './Database';
import getFirebase from './Database.js'
import './App.css';
import './CommentSection.css';

import './ApproveNreply.css';

export default function Reply() {

	const [comments,setComments] = useState([]);
	const [id,setId] = useState("");

  	useEffect(() => {
  		const firebase = getFirebase();
    	return db.collection('comments')
    		.where("approved", "==", 'true')
    		.where("reply", "==", "")
    		.onSnapshot(querySnapshot => {
      		const list = [];
      		querySnapshot.forEach(doc => {
      			const { approved, email, message, name, pageId, replier, reply, replyTimestamp, timestamp } = doc.data();
        		list.push({
          			id: doc.id,
          			approved,
          			email,
          			message,
          			name,
          			pageId,
          			replier,
          			reply,
          			replyTimestamp,
          			timestamp
        		});
      		});
      		setComments(list);
      	});
  	}, []);

	const [replyTimestamp, setReplyTimestamp] = useState(Date.now());
	const [replier, setReplier] = useState("");
	const [reply, setReply] = useState("");

  	const handleReply = (event) => {
		event.preventDefault();
		db.collection('comments').doc(id).update({
			replyTimestamp: replyTimestamp,
			replier: replier,
			reply: reply,
		})
		.then(() => {
			alert('The reply has been saved');
		})
		.catch((error) => {
			alert(error.message);
		});

		setReply('');
		setReplier('');
	};
	
	return (
		<>
			<h2 className='comment_section_heading'>&nbsp;Comments for reply</h2>
			<div className='comment_viewer'>
        		{
        			comments.map((comment, index) => {
          				return(
            				<div key={index} className="comment_text">
            					<form className='admin_form' onSubmit={handleReply}>
            					{/*<form className='form'>*/}
            						<h4 className='commenter'> Article: {comment.pageId}</h4>
            						<h4 className='commenter'> Posted on: {(new Date(comment.timestamp)).toDateString()}</h4>
            						<h4 className='commenter'> Comment by: {comment.name}</h4>
            						<h4 className='commenter'> Commenter's email: {comment.email}</h4>
            						<h4 className='commenter'> Approved: {comment.approved}</h4>
            						<h4 className='commenter'> id: {comment.id}</h4>
            						<p className='comment' >{comment.message}</p>
            						<label>Replier's name</label>
        							<input 
        								placeholder="Replier's name" 
        								value={replier}
        								onChange={(event) => setReplier(event.target.value)}
        							/>
            						<label>Reply</label>
        							<textarea 
        								placeholder="Your reply" 
        								value={reply}
        								onChange={(event) => setReply(event.target.value)}>
        							</textarea>
            						<button 
            							className='approve' 
            							type='submit' 
            							value={comment.id} 
            							onClick={(event) => setId(event.target.value)}
            						>
            							Reply
            						</button>
            					</form>
            				</div>

          				)
        			})
      			}
        	</div>
        </>
    );
}