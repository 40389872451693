import React from 'react';
import App from "../components/App";
import Approve from '../components/Approve';
import Reply from '../components/Reply';
/*import '../components/CommentSection.css';
import '../components/ApproveNreply.css';*/

export default function ApproveNreply() {
	
	return (
		<App>
			<Approve />
			<Reply />
        </App>
    );
}