import React, {useState, useEffect} from 'react';
import { db } from './Database';
import getFirebase from './Database.js'
import './App.css';
import './CommentSection.css';
import './ApproveNreply.css';

export default function Approve() {

	const [comments,setComments] = useState([]);
	const [id,setId] = useState("");

  	useEffect(() => {
  		const firebase = getFirebase();
    	return db.collection('comments')
    		.where("approved", "==", 'false')
    		.onSnapshot(querySnapshot => {
      		const list = [];
      		querySnapshot.forEach(doc => {
      			const { approved, email, message, name, pageId, replier, reply, replyTimestamp, timestamp } = doc.data();
        		list.push({
          			id: doc.id,
          			approved,
          			email,
          			message,
          			name,
          			pageId,
          			replier,
          			reply,
          			replyTimestamp,
          			timestamp,
        		});
      		});
      		setComments(list);
      	});
  	}, []);

  	const handleApprove = (event) => {
		event.preventDefault();
		db.collection('comments').doc(id).update({
			approved: 'true',
		})
		.then(() => {
			alert('The message has been approved');
		})
		.catch((error) => {
			alert(error.message);
		});
	};

	/*const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [pageId, setPageId] = useState("");
	const [timestamp, setTimestamp] = useState(Date.now());*/
	
	return (
		<>
			<h2 className='comment_section_heading'>&nbsp;Comments for approval</h2>
			<div className='comment_viewer'>
        		{
        			comments.map((comment, index) => {
          				return(
            				<div key={index} className="comment_text">
            					<form className='admin_form' onSubmit={handleApprove}>
            						<h4 className='commenter'> Article: {comment.pageId}</h4>
            						<h4 className='commenter'> Posted on: {(new Date(comment.timestamp)).toDateString()}</h4>
            						<h4 className='commenter'> Comment by: {comment.name}</h4>
            						<h4 className='commenter'> Commenter's email: {comment.email}</h4>
            						<h4 className='commenter'> id: {comment.id}</h4>
            						<p className='comment' >{comment.message}</p>
            						<button 
            							className='approve' 
            							type='submit' 
            							value={comment.id} 
            							onClick={(event) => setId(event.target.value)}
            						>
            							Approve
            						</button>
            					</form>
            				</div>

          				)
        			})
      			}
        	</div>
        </>
    );
}